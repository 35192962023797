// 判断对象的值是否为空
function isObjEmpty(obj) {
	return Boolean(Object.values(obj).filter((item) => item === '' || item === null).length)
}

// 手机号正则
function phoneReg(phone) {
	return new Promise((resolve, reject) => {
		let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8,6]|8[0-9]|9[1,8,9])\d{8}$/
		if (!reg.test(phone) || phone.length !== 11) {
			reject('请输入正确的手机号')
		} else {
			resolve(true)
		}
	})
}

// 下载
function downloadFile(url, filename) {
	if (!url) return
	let link = document.createElement('a') //创建a标签
	link.style.display = 'none' //使其隐藏
	link.target = 'view_window'
	link.href = url //赋予文件下载地址
	link.setAttribute('download', filename) //设置下载属性 以及文件名
	document.body.appendChild(link) //a标签插至页面中
	link.click() //强制触发a标签事件
	document.body.removeChild(link)
}

module.exports = {
	isObjEmpty,
	phoneReg,
	downloadFile
}
