<template>
	<div class="wrap">
		<div class="title title-vertical flex-space-between">
			<span>{{ title }}</span>
		</div>
		<!-- :id="listId" -->
		<List></List>
	</div>
</template>

<script>
import List from '@/components/list/list.vue'
import {EventBus} from '@/utils/event-bus'

export default {
	props: {
		title: {
			type: String,
			default() {
				return ''
			}
		},
		id: {
			type: Number,
			default() {
				return null
			}
		}
	},
	components: {List},
	data() {
		return {
			listId: null
		}
	},

	mounted() {
		// EventBus.$on('listId', (res) => {
		//   this.listId = res
		//   console.log(this.listId)
		// })
	},
	destroyed() {
		EventBus.$off('listId')
	},

	methods: {},
	watch: {
		// id: {
		//   handler(val) {
		//     this.listId = val
		//     console.log('list', val)
		//   },
		//   deep: true
		// }
	}
}
</script>

<style lang="less" scoped>
.wrap {
	padding: 30px 0 69px 0;
	background: #fff;
}
</style>
